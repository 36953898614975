import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {useLoader, useThree} from "@react-three/fiber";
import {AudioLoader} from "three";
import GlobalState from "../store/AppGlobalState";
import AppDataLoadingState from "../store/DataLoadingState";
import {audioListener} from "../helpers/singletones";
import {MAIN_BACKGROUND_VOLUME} from "./AudioController";

/**
 * Компонент для воспроизведения фоновой музыки.
 */
//TODO: переписать на работу с AudioController
const BackgroundAudio = () => {

    const backgroundMusic = useRef();
    const {camera} = useThree();
    const backgroundMusicBuffer = useLoader(AudioLoader, "/assets/sounds/main_background_sound.wav");
    AppDataLoadingState.backgroundSoundIsLoaded = true;

    useEffect(() => {
        backgroundMusic.current.setBuffer(backgroundMusicBuffer);
        backgroundMusic.current.setLoop(true);
        backgroundMusic.current.setVolume(MAIN_BACKGROUND_VOLUME);
        camera.add(audioListener);
    }, []);

    useEffect(() => {
        const {turnOffLoaderScreen, playBackgroundSound} = GlobalState;

        if (turnOffLoaderScreen) {
            if (playBackgroundSound) {
                backgroundMusic.current.play();
            } else {
                backgroundMusic.current.pause();
            }
        }
    }, [GlobalState.turnOffLoaderScreen, GlobalState.playBackgroundSound])

    return <audio ref={backgroundMusic} args={[audioListener]}/>
}

export default observer(BackgroundAudio);
