import React from "react";
import {observer} from "mobx-react";
import FeatureContent, {FeatureHeader, FeatureText} from "../FeatureContent";
import GlobalState from "../../../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../../../helpers/constants";
import audioController from "../../../AudioController";
import {motion} from "framer-motion";
import { animationShow } from "helpers/constants";
import "./NoiseCancellationContent.less";

/**
 * Контент на виде {@link POINT_SETTINGS.NoiseCancel}
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NoiseCancellationContent = () => {

    const onTryNowClick = () => {
        GlobalState.toggleSuppressNoiseAnimation();
        audioController.toggleCityNoiseAudioPlaying();
    }

    return (
        <FeatureContent visible={GlobalState.currentPointIsEqual(POINT_SETTINGS.NoiseCancel)}
                        className="noise-cancellation-content">
            <FeatureHeader>
                {['—','A','C','T','I','V','E',' ','N','O','I','S','E',' ','C','A','N','C','E','L','L','A','T','I','O','N'].map((letter, index, array) =>(
                    <motion.span key={letter+index} initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(index*1.4/array.length)}>{letter}</motion.span>))
                    }
            </FeatureHeader>
            <FeatureText>
                <motion.p initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(1.3)}>
                    Lose yourself in the music, with active noise cancellation. Active noise cancellation lets you shut
                    out the world around you. Tune out your surroundings and immerse yourself in a much-loved album,
                    focus on a podcast, or enjoy a moment of silence. With active noise cancellation, you control what
                    you hear – and when.
                </motion.p>
            </FeatureText>
            <motion.button
                initial={{opacity: 0}}
                exit={animationShow.exit}
                animate={{opacity: 1, transition: {duration: 1}}}
                className="try-now-button"
                onClick={onTryNowClick}>
                {GlobalState.suppressNoise ? "Turn off" : "Try now"}
            </motion.button>
        </FeatureContent>
    );
}

export default observer(NoiseCancellationContent);
