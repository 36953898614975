import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useThree, useFrame } from "@react-three/fiber";
import GlobalState from "../store/AppGlobalState";
import { useOrbit } from "../helpers/use-orbit";
import { ScrollControls, useScroll } from "@react-three/drei";
import { POINT_SETTINGS } from "helpers/constants";
import { Vector3, CatmullRomCurve3 } from 'three';
import AppGlobalState from "../store/AppGlobalState";

const ScrollIntroWrap = () => {
    return (
        <ScrollControls pages={6}>
            <ScrollIntro />
        </ScrollControls>
    )
}

const ScrollIntro = observer(() => {
    const { camera, gl } = useThree();
    const controls = useOrbit(camera, gl, { enabled: false });

    const scroll = useScroll()

    //отключить скролл, если находимся внутри одной из точек фичей
    useEffect(() => {
        if (!GlobalState.currentPointIsEqual(POINT_SETTINGS.Intro) && !GlobalState.currentPointIsDefault()) {
            scroll.el.style.overflow = "hidden"
        } else {
            scroll.el.style.overflow = "hidden auto"
        }
    }, [GlobalState.currentPointOfInterest]);


    const phases = [
        0 * 0.9 / 4,
        1 * 0.9 / 4,
        2 * 0.9 / 4,
        3 * 0.9 / 4,
        4 * 0.9 / 4 //0.9 finish
    ]
    const curvesPos = useMemo(() => {
        return [
            new CatmullRomCurve3([
                new Vector3(-5, 0, 1),
                new Vector3(-3, 0, 3),
            ]),
            new CatmullRomCurve3([
                new Vector3(-1, -0.3, 2),
                new Vector3(0, -0.3, 1),
            ]),
            new CatmullRomCurve3([
                new Vector3(3.8, 1, 1.2),
                new Vector3(3.8, -2, 1),
            ]),
            new CatmullRomCurve3([
                new Vector3(-5, 0, 4),
                POINT_SETTINGS.Default.cameraPosition,
            ]),
        ]
    })
    const cameraUps = useMemo(() => {
        return [
            new Vector3(0, 1, 0),
            new Vector3(0, 1, 1),
            new Vector3(0, 1, -1),
            new Vector3(0, 1, 0)
        ]
    })
    const controlTargets = useMemo(() => {
        return [
            new Vector3(0, 0, 0),
            new Vector3(-1.8, -1, 0),
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 0)
        ]
    })
    //1-40      trg 000, arc around, flat
    //41-81     inside arc around left, tilted
    //82-121    outside arc around right, tilted
    //122-162   arc into default, flat

    useFrame((st, dt) => {
        const scrollOffset = scroll.offset;

        if (AppGlobalState.introScrollOffset !== +scrollOffset.toFixed(4)) {
            AppGlobalState.introScrollOffset = +scrollOffset.toFixed(4);
        }

        if (scrollOffset >= phases[phases.length - 1]) { //последняя фаза, когда уже появляются точки
            if (GlobalState.currentPointIsEqual(POINT_SETTINGS.Intro)) {
                GlobalState.currentPointOfInterest = POINT_SETTINGS.Default;
            }
        } else {
            if (!GlobalState.currentPointIsEqual(POINT_SETTINGS.Intro)) {
                GlobalState.currentPointOfInterest = POINT_SETTINGS.Intro;
            }
            ////анимации интро
            for (let id = 3; id >= 0; id--) {
                const p0 = phases[id];
                const p1 = phases[id + 1];
                if (scrollOffset >= p0) {
                    const sr = scroll.range(p0, p1 - p0) //(from,length)->[0,1]
                    let pos = curvesPos[id].getPointAt(sr);
                    camera.position.copy(pos);
                    camera.up.copy(cameraUps[id])
                    controls?.target.copy(controlTargets[id])
                    break;
                }
            }
            controls?.update()
        }
    });

    return null;
})

export default observer(ScrollIntroWrap);