import React from "react";

/**
 * Компонент для отображения тёмных стен на сцене. Используются для добавления "фонарей" (lights.jsx - WallLights)
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Walls = () => {

    return (
        <group>
            <mesh position={[0, 0, -15]} rotation={[0, 0, 0]}>
                <planeGeometry args={[60, 60]}/>
                <meshPhongMaterial args={[{color: "#3e3e3e"}]}/>
            </mesh>
            <mesh position={[15, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
                <planeGeometry args={[50, 50]}/>
                <meshPhongMaterial args={[{color: "#3e3e3e"}]}/>
            </mesh>
        </group>
    )
}
