import {gsap} from 'gsap';

/**
 * Smooth animation of vector
 */
export const interpVector = (fromVector, toVector, onUpdateCB, onCompleteCB) => {
    return gsap.to(fromVector, {
        ...toVector,
        duration: 1,
        ease: 'power2.inOut',
        onUpdate: () => {
            onUpdateCB?.(fromVector);
        },
        onComplete: () => {
            onCompleteCB?.(fromVector);
        }
    });
};

/**
 * Smooth animation of vector
 */
export const interpValue = (from, to, onUpdateCB, onCompleteCB, duration) => {
    const container = {value: from};
    return gsap.to(container, {
        value: to,
        duration: duration,
        ease: 'power2.inOut',
        onUpdate: () => {
            onUpdateCB?.(container.value);
        },
        onComplete: () => {
            onCompleteCB?.();
        }
    });
};
