export const MAIN_BACKGROUND_VOLUME = 0.8;
export const CITY_NOISE_VOLUME = 0.8;

/**
 * Класс-контроллер. Содержит рефы на воспроизводимые звуки в сцене и методы для управления воспроизведением.
 */
class AudioController {
    cameraZoomInAudio = null;

    cameraZoomOutAudio = null;

    noiseCancellationClickAudio = null;

    cityNoiseAudio = null;

    cityNoiseSuppressedAudio = null;

    tryNowNoiseCancellationAudio = null;

    playTryNowNoiseCancellationAudio = () => {
        if (this.audioRefIsDefined(this.tryNowNoiseCancellationAudio)) {
            this.tryNowNoiseCancellationAudio.current?.play();
        }
    };

    stopTryNowNoiseCancellationAudio = () => {
        if (
            this.audioRefIsDefined(this.tryNowNoiseCancellationAudio) &&
            this.tryNowNoiseCancellationAudio.current.isPlaying
        ) {
            this.tryNowNoiseCancellationAudio.current?.stop();
        }
    };

    playCityNoiseAudio = () => {
        if (this.audioRefIsDefined(this.cityNoiseAudio)) {
            this.cityNoiseAudio.current?.play();
            this.cityNoiseAudio?.current.setVolume(CITY_NOISE_VOLUME);
        }
    };

    stopCityNoiseAudio = () => {
        if (
            this.audioRefIsDefined(this.cityNoiseAudio) &&
            this.cityNoiseAudio.current.isPlaying
        ) {
            this.cityNoiseAudio.current?.stop();
        }
    };

    playCityNoiseSuppressedAudio = () => {
        if (this.audioRefIsDefined(this.cityNoiseSuppressedAudio)) {
            this.cityNoiseSuppressedAudio.current?.play();
        }
    };

    stopCityNoiseSuppressedAudio = () => {
        if (
            this.audioRefIsDefined(this.cityNoiseSuppressedAudio) &&
            this.cityNoiseSuppressedAudio.current.isPlaying
        ) {
            this.cityNoiseSuppressedAudio.current?.stop();
        }
    };

    toggleCityNoiseAudioPlaying = () => {
        if (
            !this.audioRefIsDefined(this.cityNoiseAudio) ||
            !this.audioRefIsDefined(this.cityNoiseSuppressedAudio)
        ) {
            return;
        }

        const {
            cityNoiseAudio,
            cityNoiseSuppressedAudio,
            playCityNoiseAudio,
            playTryNowNoiseCancellationAudio,
            stopTryNowNoiseCancellationAudio,
            stopCityNoiseAudio,
            playCityNoiseSuppressedAudio,
            stopCityNoiseSuppressedAudio
        } = this;

        const isCityNoiseSuppressed =
            !cityNoiseAudio.current.isPlaying &&
            cityNoiseSuppressedAudio.current.isPlaying;

        if (isCityNoiseSuppressed) {
            stopCityNoiseSuppressedAudio();
            stopTryNowNoiseCancellationAudio();
            playCityNoiseAudio();
        } else {
            stopCityNoiseAudio();
            playTryNowNoiseCancellationAudio();
            playCityNoiseSuppressedAudio();
        }
    };

    playNoiseCancellationClickAudio = () => {
        if (this.audioRefIsDefined(this.noiseCancellationClickAudio)) {
            this.noiseCancellationClickAudio.current.play();
        }
    };

    playZoomIn = () => this.playCameraZoomSound(true);

    playZoomOut = () => this.playCameraZoomSound(false);

    playCameraZoomSound = (zoomIn) => {
        const sound = zoomIn ? this.cameraZoomInAudio : this.cameraZoomOutAudio;

        if (this.audioRefIsDefined(sound)) {
            if (sound.current.isPlaying) {
                sound.current?.stop();
            }
            sound.current?.play();
        }
    };

    audioRefIsDefined = (audioRef) => {
        if (!audioRef?.current) {
            console.error(
                "Audio ref or ref.current is undefined. Ref link:",
                audioRef
            );
            return false;
        }

        return true;
    };
}

const audioController = new AudioController();
export default audioController;
