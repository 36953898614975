import React from "react";
import {observer} from "mobx-react";
import FeatureContent, {FeatureHeader, FeatureText} from "../FeatureContent";
import GlobalState from "../../../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../../../helpers/constants";
import {motion} from "framer-motion";
import { animationShow } from "helpers/constants";
import "./SoftAndComfortableContent.less";

/**
 * Контент на виде {@link POINT_SETTINGS.SoftAndComfortable}
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SoftAndComfortableContent = () => {
    return (
        <FeatureContent visible={GlobalState.currentPointIsEqual(POINT_SETTINGS.SoftAndComfortable)}
                        className="soft-and-comfortable-content">
            <FeatureHeader>{['—','S','O','F','T',' ','A','N','D',' ','C','O','M','F','O','R','T','A','B','L','E'].map((letter, index, array) => (
                    <motion.span key={letter+index} initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(index*1.4/array.length)}>{letter}</motion.span>
            ))}</FeatureHeader>
            <FeatureText>
                <motion.p  initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(1.3)}>
                    The headphones are lightweight with a specially-designed ergonomic headband for long-term comfort.
                    A crafted fabric case makes it easy to carry and protect your headphones and accessories while on
                    the move – ensuring durability as well as portability.
                </motion.p>
            </FeatureText>
        </FeatureContent>
    );
}

export default observer(SoftAndComfortableContent);
