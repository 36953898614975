import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {motion} from "framer-motion";
import GlobalState from "../../store/AppGlobalState";
import "./Ripple.less";

/**
 * Перечисление видов анимациий (framer-motion)
 *
 * @type {{hide: string, expand: string, initial: string, show: string}}
 */
const rippleAnimationStates = {
    // начальная анимация для фиксации отображения на старте
    initial: "initial",

    // анимация для показа кнопок после включения стандартного вида
    show: "show",

    // анимация для сокрытия кнопок после включения не стандартного вида
    hide: "hide",

    // анимация для расскрытия кнопок после завершения скрола пререндера
    expand: "expand"
}

/**
 * Компонент для отображения мерцающих кнопок для переключения видов. Анимируется для показа в соответствии
 * со стадиями показа/завершения пререндера и переходами между видами. Привязан к контексту ThreeJS на канвасе.
 *
 * @type {function({onClick: *, onMouseEnter: *, onMouseLeave: *})}
 */
export const Ripple = observer(({onClick, onMouseEnter, onMouseLeave}) => {

    const variants = {
        [rippleAnimationStates.initial]: {
            scale: 0,
            transition: {
                duration: 0
            }
        },
        [rippleAnimationStates.show]: {
            scale: 1,
            transition: {
                delay: 0.5,
                duration: 0.3
            }
        },
        [rippleAnimationStates.hide]: {
            scale: 0,
            transition: {
                duration: 0.3
            }
        },
        [rippleAnimationStates.expand]: {
            scale: 1,
            transition: {
                delay: 2.5,
                duration: 0.7
            }
        },
    }

    const [animate, setAnimate] = useState(rippleAnimationStates.initial);

    // Когда завершаем показ пререндера - начинаем раскрытие кнопок
    useEffect(() => {
        if (GlobalState.threeDHeadphonesCanShow) {
            setAnimate(rippleAnimationStates.expand);
        }
    }, [GlobalState.threeDHeadphonesCanShow]);

    // После перехода к стандартному виду - поканачинаем показывать/прятать кнопки
    useEffect(() => {
        if (!GlobalState.threeDHeadphonesCanShow) {
            return;
        }

        if (GlobalState.currentPointIsDefault()) {
            setAnimate(rippleAnimationStates.show);
        } else {
            setAnimate(rippleAnimationStates.hide);
        }
    }, [GlobalState.currentPointOfInterest]);

    return (
        <div className="ripple">
            <motion.div className="ripple-container"
                        variants={variants}
                        animate={animate}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                <span/>
            </motion.div>
        </div>
    );
});
