import React from "react";
import {observer} from "mobx-react";
import FeatureContent, {FeatureHeader, FeatureText} from "../FeatureContent";
import GlobalState from "../../../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../../../helpers/constants";
import {motion} from "framer-motion";
import { animationShow } from "helpers/constants";
import "./ErgonomicHeadBandContent.less";

/**
 * Контент на виде {@link POINT_SETTINGS.ErgonomicHeadband}
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ErgonomicHeadBandContent = () => {
    return (
        <FeatureContent visible={GlobalState.currentPointIsEqual(POINT_SETTINGS.ErgonomicHeadband)}
                        className="ergonomic-headband-content">
            <FeatureHeader>{['—','E','R','G','O','N','O','M','I','C',' ','H','E','A','D','B','A','N','D'].map((letter, index, array) =>(
                <motion.span key={letter+index} initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(index*1.4/array.length)}>{letter}</motion.span>))}</FeatureHeader>
            <FeatureText>
                <motion.p initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(1.3)}>
                        Lambskin leather. Memory foam cushions. An ergonomic pressure-relieving headband that fits different
                        types of head. All brought together by a lightweight aluminium frame. These versatile over-ear
                        headphones give you cocoon-like comfort that lets you listen all day (and night).
                </motion.p>
            </FeatureText>
        </FeatureContent>
    );
}

export default observer(ErgonomicHeadBandContent);
