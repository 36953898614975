import React from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import GlobalState from "../../../store/AppGlobalState";
import PointsOfInterestNavigation from "./PointsOfInterestNavigation";
import {POINT_SETTINGS} from "../../../helpers/constants";
import audioController from "../../AudioController";
import { LeftTopLogo } from "./LeftTopLogo";
import "./SiteDataHeader.less";

const SiteDataHeader = () => {

    const {currentPointIsOneOfFeature} = GlobalState;
    const className = classNames("header", {"show-points-navigation": currentPointIsOneOfFeature()});

    return (
        <section className={className}>
            <div className="left-container">
                {currentPointIsOneOfFeature() ? <PointsOfInterestNavigation/> : <LeftTopLogo/>}
            </div>

            <div className="right-container">
                <RightTopButton/>
            </div>
        </section>
    );
}

const RightTopButton = () => {

    const {setCurrentPointOfInterest, toggleHowToBuyModal, currentPointIsOneOfFeature} = GlobalState;
    const onCloseClick = () => {
        audioController.playZoomOut();
        setCurrentPointOfInterest(POINT_SETTINGS.Default);
    }

    return (
        <button className="right-top-logo-button" onClick={currentPointIsOneOfFeature() ? onCloseClick : toggleHowToBuyModal}>
            <div className="logo-description">
                {
                    !currentPointIsOneOfFeature() ? "How to Buy" :
                        <>
                            <span>Close</span>
                            <div className="logo-close"/>
                        </>
                }
            </div>
        </button>
    );
};

export default observer(SiteDataHeader);
