import React from 'react';
import {observer} from "mobx-react";
import {Html} from '@react-three/drei'
import {AnimatePresence, motion} from "framer-motion";
import GlobalState from "../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../helpers/constants";
import "./MicrophoneAnimation.less";

const animationVariants = {
    initial: {scale: 0},
    animate: {
        scale: 1,
        transition: {delay: 0.7, duration: 1}
    },
    exit: {
        scale: 0,
        transition: {duration: 0.5}
    }
};

/**
 * Компонент анимации микрофона. Отображается на виде Microphone в виде разлетающихся кургов. Привязан к контексту
 * ThreeJS на канвасе.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MicrophoneAnimation = () => {
    return (
        <Html position={[1.38, -2, 0.02]}>
            <AnimatePresence>
                {GlobalState.currentPointIsEqual(POINT_SETTINGS.Microphone) && (
                    <motion.div className="microphone-animation"
                                initial={animationVariants.initial}
                                animate={animationVariants.animate}
                                exit={animationVariants.exit}>
                        <motion.div/>
                    </motion.div>
                )}
            </AnimatePresence>
        </Html>
    )
}

export default observer(MicrophoneAnimation);

