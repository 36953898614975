import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import PrernderViewContent from "./prerender-content/PrernderViewContent";
import GlobalState from "../../../store/AppGlobalState";
import NoiseCancellationContent from "./noise-cancellation-content/NoiseCancellationContent";
import MicrophoneContent from "./microphone-content/MicrophoneContent";
import ErgonomicHeadbandContent from "./ergonomic-headband-content/ErgonomicHeadbandContent";
import SoftAndComfortableContent from "./soft-and-comfortable-content/SoftAndComfortableContent";
import FeaturesContent from "./default-content/FeaturesContent";
import {HowToBuyModal} from "../how-to-buy-modal/HowToBuyModal";
import "./MainContentContainer.less";

/**
 * Контейнер для отображения основного контента (меню, описание фичей и т.д.)
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MainContentContainer = () => {
    return (
        <section className="main-content-container">
            <ScrollBar/>
            <div className="content">
                <Content/>
            </div>
            <HowToBuyModal isOpened={GlobalState.isHowToBuyOpened}/>
        </section>
    );
}

export default observer(MainContentContainer);

const Content = observer(() => {
    return (
        <>
            <PrernderViewContent/>
            <FeaturesContent/>
            <NoiseCancellationContent/>
            <MicrophoneContent/>
            <ErgonomicHeadbandContent/>
            <SoftAndComfortableContent/>
        </>
    );
})

/**
 * Компонент для альтернативного показа скроллбара.
 */
const ScrollBar = observer(() => {

    const scrollBarRef = useRef();
    const scrollBarCaretRef = useRef();
    const [topPosition, setTopPosition] = useState(0);

    useEffect(() => {
        if (scrollBarRef.current && scrollBarCaretRef.current) {
            setTopPosition(GlobalState.introScrollOffset * (scrollBarRef.current.clientHeight - scrollBarCaretRef.current.clientHeight))
        }
    }, [GlobalState.introScrollOffset]);

    const className = classNames("scroll-bar-container", {"hidden": GlobalState.currentPointIsOneOfFeature()});

    return (
        <div className={className}>
            <div className="scroll-bar" ref={scrollBarRef}>
                <div className="scroll-bar-caret" style={{top: topPosition}} ref={scrollBarCaretRef}/>
            </div>
        </div>
    );
})
