import React from "react";
import {POINT_SETTINGS} from "../../helpers/constants";
import {observer} from "mobx-react";
import InscriptionContainer, {InscriptionContent} from "./InscriptionContainer";

/**
 * Все кнопки с описаниями на наушниках. Привязан к контексту ThreeJS на канвасе.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Inscriptions = () => {
    return (
        <>
            <MicrophoneInscription delay={2.6}/>
            <NoiseCancelInscription delay={1.4}/>
            <HeadbandOffsetInscription delay={2.2}/>
            <HeadbandTopInscription delay={1.8}/>
        </>
    )
}

export default observer(Inscriptions);

const MicrophoneInscription = ({delay}) => {
    return (
        <InscriptionContainer className="microphone" pointOfInterest={POINT_SETTINGS.Microphone}>
            <InscriptionContent inscriptionHeader="—microphone—" delay={delay}>
                4 microphones for speech and Virtual boom arm for crystal clear conversations.
            </InscriptionContent>
        </InscriptionContainer>
    );
}

const NoiseCancelInscription = ({delay}) => {
    return (
        <InscriptionContainer className="noise-cancellation" pointOfInterest={POINT_SETTINGS.NoiseCancel}>
            <InscriptionContent inscriptionHeader="—noise cancellation—" delay={delay}>
                You can immerse yourself in your music or simply enjoy complete silence. And when you need to hear
                what's going on around you, activate the Transparency Mode.
            </InscriptionContent>
        </InscriptionContainer>
    );
}

const HeadbandOffsetInscription = ({delay}) => {
    return (
        <InscriptionContainer className="soft-and-comfortable" pointOfInterest={POINT_SETTINGS.SoftAndComfortable}>
            <InscriptionContent inscriptionHeader="—soft and comfortable—" delay={delay}>
                Weighing just 255g, headphones is one of the lightest headphones in its class. Ergonomics, light weight
                and easy handling make the model an ideal travel companion.
            </InscriptionContent>
        </InscriptionContainer>
    );
}

const HeadbandTopInscription = ({delay}) => {
    return (
        <InscriptionContainer className="ergonomic-headband" pointOfInterest={POINT_SETTINGS.ErgonomicHeadband}>
            <InscriptionContent inscriptionHeader="—ergonomic headband—" delay={delay}>
                Soft leather on the ear cushions and adaptive memory foam provide a snug yet comfortable fit so you can
                wear the headphones for extended periods of time without feeling tired.
            </InscriptionContent>
        </InscriptionContainer>
    );
}