import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {Billboard, Html} from "@react-three/drei";
import classNames from "classnames";
import {motion} from "framer-motion";
import {AudioLoader} from "three";
import {Ripple} from "./Ripple";
import GlobalState, {pointOfInterestEquals} from "../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../helpers/constants";
import {useLoader, useThree} from "@react-three/fiber";
import AppDataLoadingState from "../../store/DataLoadingState";
import {audioListener} from "../../helpers/singletones";
import audioController from "../AudioController";
import "./InscriptionContainer.less";

/**
 * Компонент-контейнер для описания фичи. Содержит кнопку и переданный контент, обёрнутый в {@link InscriptionContent}.
 * Привязан к контексту ThreeJS на канвасе.
 *
 * @param children
 * @param pointOfInterest
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const InscriptionContainer = ({children, pointOfInterest, className}) => {

    const [hovered, setHovered] = useState(false);
    const {camera} = useThree();

    const hoverSound = useRef();
    const hoverSoundBuffer = useLoader(AudioLoader, "/assets/sounds/button_hover.mp3");
    AppDataLoadingState.buttonHoverSoundIsLoaded = true;

    useEffect(() => {
        camera.add(audioListener);
        hoverSound.current.setBuffer(hoverSoundBuffer);
    }, []);

    const onRippleClick = () => {
        if (!GlobalState.isInterpolating) {
            if (pointOfInterestEquals(pointOfInterest, POINT_SETTINGS.NoiseCancel)) {
                audioController.playNoiseCancellationClickAudio();
            } else {
                audioController.playZoomIn();
            }

            GlobalState.setCurrentPointOfInterest(pointOfInterest);
        }
    }

    const onMouseEnterAction = () => {
        setHovered(true);
        hoverSound.current.play();
    }

    const onMouseLeaveAction = () => {
        setHovered(false);
    }

    const resultClassName = classNames(
        "inscription-container",
        className,
        {"hovered": hovered && GlobalState.currentPointIsDefault()},
        {"hidden": !GlobalState.currentPointIsDefault()}
    );

    return (
        <>
            <audio ref={hoverSound} args={[audioListener]}/>

            <Billboard position={pointOfInterest.pointPosition}>
                <Html as="div" wrapperClass={resultClassName} zIndexRange={[100, 0]}>
                    <div className="absolute-container">
                        <Ripple
                            onClick={onRippleClick}
                            onMouseEnter={onMouseEnterAction}
                            onMouseLeave={onMouseLeaveAction}/>
                        <div className="inscription">
                            {children}
                        </div>
                    </div>
                </Html>
            </Billboard>
        </>
    );
}

export default observer(InscriptionContainer);

const headerStates = {
    // начальная анимация для фиксации отображения на старте
    initial: "initial",

    // анимация для показа заголовка после завершения скрола пререндера
    show: "show",

    // анимация для сокрытия заголовка после включения не стандартного вида
    hide: "hide",

    // анимация для показа заголовка после включения стандартного вида
    showOnDefault: "showOnDefault",
}

/**
 * Компонент для отображения текстового контента фичи. Содержит заголовок и текст. Анимируется для показа в соответствии
 * со стадиями показа/завершения пререндера и переходами между видами.
 *
 * @type {function({children: *, inscriptionHeader: *})}
 */
export const InscriptionContent = observer(({children, inscriptionHeader, delay = 3.5}) => {

    const variants = {
        [headerStates.initial]: {
            opacity: 0,
            transition: {
                duration: 0
            }
        },
        [headerStates.show]: {
            opacity: 1,
            transition: {
                delay: delay,
                duration: 0.5
            }
        },
        [headerStates.showOnDefault]: {
            opacity: 1,
            transition: {
                delay: 0.5,
                duration: 0.3
            }
        },
        [headerStates.hide]: {
            opacity: 0,
            transition: {
                duration: 0.3
            }
        }
    }

    const [animate, setAnimate] = useState(headerStates.initial);

    // Когда завершаем показ пререндера - начинаем показ заголовка
    useEffect(() => {
        if (GlobalState.threeDHeadphonesCanShow) {
            setAnimate(headerStates.show);
        }
    }, [GlobalState.threeDHeadphonesCanShow]);

    // После перехода к стандартному виду - поканачинаем показывать/прятать заголовок
    useEffect(() => {
        if (!GlobalState.threeDHeadphonesCanShow) {
            return;
        }

        if (GlobalState.currentPointIsDefault()) {
            setAnimate(headerStates.showOnDefault);
        } else {
            setAnimate(headerStates.hide);
        }
    }, [GlobalState.currentPointOfInterest]);

    return (
        <>
            <motion.h5 className="inscription-header" variants={variants} animate={animate}>
                {inscriptionHeader}
            </motion.h5>

            <p className="inscription-text">{children}</p>
        </>
    );
});
