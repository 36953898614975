import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useThree, useFrame} from "@react-three/fiber";
import GlobalState from "../store/AppGlobalState";
import {useOrbit} from "../helpers/use-orbit";
import {interpValue, interpVector} from "../helpers/interp-values";
import { POINT_SETTINGS } from "helpers/constants";

/**
 * Конртрол камеры + перемещение по точкам
 * @returns {null}
 * @constructor
 */
const CameraControls = () => {
    const {camera, gl} = useThree();

    const controls = useOrbit(camera, gl, {enabled: false});

    /*useEffect(() => {
        if (!!controls) {
            controls.enabled = GlobalState.threeDHeadphonesCanShow;
        }
    }, [GlobalState.threeDHeadphonesCanShow])*/

    useEffect(() => {

        if (controls) {
            GlobalState.isInterpolating = true;
            setToDefault(controls);

            interpObjects(camera, controls, GlobalState.currentPointOfInterest).then(() => {
                GlobalState.isInterpolating = false;
            });
        }
    }, [GlobalState.currentPointOfInterest]);

    useFrame(() => {
        //закончить анимации и переключиться в интро
        if (GlobalState.currentPointIsEqual(POINT_SETTINGS.Intro)
            && !GlobalState.isInterpolating)
            return;

        if (controls) {
            controls.update();
        }
    });

    return null;
}

export default observer(CameraControls);

const setToDefault = (controls) => {
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Math.PI / 2;
}

const interpObjects = (camera, controls, state) => {
    return Promise.allSettled([
        new Promise((resolve) => {
            interpVector(
                camera.position,
                state.cameraPosition,
                undefined,
                resolve
            );
        }),
        new Promise((resolve) => {
            interpVector(
                controls.target,
                state.targetPosition,
                undefined,
                resolve
            );
        }),
        new Promise((resolve) => {
            interpValue(
                camera.zoom,
                state.zoom,
                (value) => camera.zoom = value,
                resolve,
                1
            );
        })
    ]);
}
