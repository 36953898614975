import { MeshReflectorMaterial } from "@react-three/drei";
import React from 'react';


const ReflectorGround = () => {
    return (
        <mesh position={[0, -2.2, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[333, 212]}
            resolution={1024}
            mixBlur={1}
            mixStrength={15}
            depthScale={1}
            minDepthThreshold={0.85}
            color="#2b2b2b"
            metalness={1}
            roughness={1}/>
        </mesh>
    )
}


export default ReflectorGround;

