import React from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import GlobalState from "../../../store/AppGlobalState";
import "./SiteDataFooter.less";

const SiteDataFooter = ({inverseColor = false, className = ''}) => {
    const footerClassName = classNames(
        "footer",
        {"inverse": inverseColor},
        className
    );

    const toggleBackgroundSound = () => GlobalState.playBackgroundSound = !GlobalState.playBackgroundSound;

    return(
        <footer className={footerClassName}>
            <div className="right-container" onClick={toggleBackgroundSound}>
                <SoundLogo active={GlobalState.playBackgroundSound}/>
            </div>
        </footer>
    );
}

const SoundLogo = ({active}) => {
    const resultClassName = classNames(
        "sound-logo",
        {"active": active}
    );

    return (
        <ul className={resultClassName}>
            <li className="sound-logo__item"></li>
            <li className="sound-logo__item"></li>
            <li className="sound-logo__item"></li>
            <li className="sound-logo__item"></li>
            <li className="sound-logo__item"></li>
            <li className="sound-logo__item"></li>
            <li className="sound-logo__item"></li>
        </ul>
    )
};

export default observer(SiteDataFooter);
