import {useFrame} from "@react-three/fiber"
import React, {useEffect, useRef} from 'react';
import GlobalState from "../store/AppGlobalState";
import {POINT_SETTINGS} from "../helpers/constants";
import {interpValue} from "../helpers/interp-values";
import {observer} from "mobx-react";

const ringCount = 3;
let phaseAnimation = 0; //[0,1]
let opacityOfViewCoefficient = 0; // коеф-нт прозрачности, для плавного показа и удаления кругов при смене вида

const RingsGround = () => {
    const refs = new Array(ringCount).fill().map(() => useRef())

    useFrame((_, dt) => {
        phaseAnimation += dt / 8;

        for (let i = 0; i < refs.length; i++) {
            const t = (phaseAnimation + i / ringCount) % 1;
            const cur = refs[i].current;
            cur.scale.y = t;
            cur.scale.x = t;
            cur.material.opacity = Math.sin(Math.PI * t) * opacityOfViewCoefficient;
        }
    });

    // Выполняет плавное изменение коэфициента прозрачности.
    useEffect(() => {
        const [from, to] = GlobalState.currentPointIsEqual(POINT_SETTINGS.SoftAndComfortable) ? [0, 1] : [1, 0];
        if (from === opacityOfViewCoefficient) {
            interpValue(from, to, value => opacityOfViewCoefficient = value, undefined, 1);
        }
    }, [GlobalState.currentPointOfInterest]);

    const r1 = 5; //inner
    const rd = 0.09; //circle width
    const args = [r1, r1 + rd, 128]; //inner, outer, thetaSegs
    const color = "#5016F6"
    const pos = [0, -2.1, 0];
    const rot = [-Math.PI / 2, 0, 0];

    return (
        <>
            {refs.map((ref, index) => (
                <mesh key={index} ref={ref} position={pos} rotation={rot} visible>
                    <ringBufferGeometry args={args}/>
                    <meshBasicMaterial attach="material" color={color} transparent/>
                </mesh>
            ))}
        </>

    )
}

export default observer(RingsGround);
