import React, {useEffect, useRef} from "react";
import {useLoader, useThree} from "@react-three/fiber";
import {AudioLoader} from "three";
import AppDataLoadingState from "../store/DataLoadingState";
import {audioListener} from "../helpers/singletones";
import audioController from "./AudioController";

/**
 * Компонент для загрузки и нициализации аудио рефов в {@link AudioController}
 */
const CommonAudio = () => {

    const {camera} = useThree();

    const zoomInAudio = useRef();
    const zoomOutAudio = useRef();
    const noiseCancellationClickAudio = useRef();
    const cityNoiseAudio = useRef();
    const cityNoiseSuppressedAudio = useRef();
    const tryNowNoiseCancellationAudio = useRef();

    const zoomInAudioBuffer = useUIAudioLoader("zoom_in.mp3");
    const zoomOutAudioBuffer = useUIAudioLoader("zoom_out.mp3");
    const noiseCancellationClickAudioBuffer = useUIAudioLoader("noise_cancellation_click.mp3");
    const cityNoiseAudioBuffer = useUIAudioLoader("city_noise.wav");
    const cityNoiseSuppressedAudioBuffer = useUIAudioLoader("city_noise_suppressed.wav");
    const tryNowNoiseCancellationAudioBuffer = useUIAudioLoader("try_now_noise_cancellation.mp3");

    AppDataLoadingState.allUISoundsIsLoaded = true;

    useEffect(() => {
        setAudioParams(zoomInAudio, zoomInAudioBuffer, false);
        setAudioParams(zoomOutAudio, zoomOutAudioBuffer, false);
        setAudioParams(noiseCancellationClickAudio, noiseCancellationClickAudioBuffer, false);
        noiseCancellationClickAudio.current.onEnded = () => {
            noiseCancellationClickAudio.current.isPlaying = false;
            audioController.playCityNoiseAudio();
        };

        setAudioParams(cityNoiseAudio, cityNoiseAudioBuffer, true);
        setAudioParams(cityNoiseSuppressedAudio, cityNoiseSuppressedAudioBuffer, true);
        setAudioParams(tryNowNoiseCancellationAudio, tryNowNoiseCancellationAudioBuffer, false);

        camera.add(audioListener);
    }, []);

    audioController.cameraZoomInAudio = zoomInAudio;
    audioController.cameraZoomOutAudio = zoomOutAudio;
    audioController.noiseCancellationClickAudio = noiseCancellationClickAudio;
    audioController.cityNoiseAudio = cityNoiseAudio;
    audioController.cityNoiseSuppressedAudio = cityNoiseSuppressedAudio;
    audioController.tryNowNoiseCancellationAudio = tryNowNoiseCancellationAudio;

    return (
        <>
            <audio ref={zoomInAudio} args={[audioListener]}/>
            <audio ref={zoomOutAudio} args={[audioListener]}/>
            <audio ref={noiseCancellationClickAudio} args={[audioListener]}/>
            <audio ref={cityNoiseAudio} args={[audioListener]}/>
            <audio ref={cityNoiseSuppressedAudio} args={[audioListener]}/>
            <audio ref={tryNowNoiseCancellationAudio} args={[audioListener]}/>
        </>
    );
}

/**
 * Функция для установки стандартных настроек аудио (для DRY)
 */
const setAudioParams = (audioRef, audioBuffer, loop) => {
    audioRef.current.setBuffer(audioBuffer);
    audioRef.current.setLoop(loop);
}

const useUIAudioLoader = (trackName) => useLoader(AudioLoader, `/assets/sounds/${trackName}`);

export default CommonAudio;
