import { Vector3 } from "three";

/**
 * Параметры для видов. Каждая точка содержит:
 * - name - не используется
 * - pointPosition - положение кнопки на сцене с наушниками
 * - cameraPosition - положение камеры при фокусе
 * - targetPosition - положение "цели" для правильного направления камеры
 * - zoom - зум камеры
 */
export const POINT_SETTINGS = {
    Intro: {
        name: "intro",
        pointPosition: new Vector3(999, 0, 0),
        cameraPosition: new Vector3(0.5, 0, 6),
        targetPosition: new Vector3(0, 0, 0),
        zoom: 1
    },
    Default: {
        name: "features",
        pointPosition: new Vector3(0, 0, 0),
        cameraPosition: new Vector3(0.5, 0, 6),
        targetPosition: new Vector3(0, 0, 0),
        zoom: 1
    },
    Microphone: {
        name: "microphone",
        pointPosition: new Vector3(2, -1.4, -0.4),
        cameraPosition: new Vector3(1.05, -3.2, 0),
        targetPosition: new Vector3(1.05, -1.2, -0.1),
        zoom: 1
    },
    ErgonomicHeadband: {
        name: "ergonomic-headband",
        pointPosition: new Vector3(-1.2, 2.0, 0.2),
        cameraPosition: new Vector3(0, 1, 3),
        targetPosition: new Vector3(0, 2, 0),
        zoom: 1.2
    },
    SoftAndComfortable: {
        name: "soft-and-comfortable",
        pointPosition: new Vector3(1.6, 1.6, 0.1),
        cameraPosition: new Vector3(-6, 1, 4),
        targetPosition: new Vector3(0, 0, 0),
        zoom: 1
    },
    NoiseCancel: {
        name: "noise-cancellation",
        pointPosition: new Vector3(-1.9, -1.2, 0.6),
        cameraPosition: new Vector3(-3.2, -1.7, 1.0),
        targetPosition: new Vector3(-1.6, -1.2, 0.1),
        zoom: 1
    }
};

/**
 * Основные характеристики продукта
 */
export const CHARACTERISTICS = [
    {
        name: "DESIGNER",
        description: "Jakob Wagner"
    },
    {
        name: "DIMENSIONS",
        description: "18,0 x 18,6 x 5,1 см"
    },
    {
        name: "COLOR",
        description: "Black Natural"
    },
    {
        name: "MATERIALS",
        description: "Aluminium, fabric, leather, polymer and rubber"
    },
    {
        name: "WEIGHT",
        description: "215g/225g (with audio cable)"
    },
    {
        name: "OPERATING FREQUENCY RANGE",
        description: "20 - 22,000 Hz"
    },
    {
        name: "CABLE",
        description:
            "Audio cable 1.2 m with 3.5 mm jack USB-A to USB-C charging cable 1.25 m"
    },
    {
        name: "BLUETOOTH",
        description: `
            Bluetooth 4.2, AAC codec
            Compatible with Bluetooth 1.1 - 4.2
        `
    },
    {
        name: "CONTROL",
        description:
            "3 mode switches: on/off, ANC and Transparency mode 3 buttons for volume up/down, Bluetooth, playback, etc."
    },
    {
        name: "DYNAMIC",
        description: "Electrodynamic, diameter 40 mm"
    },
    {
        name: "MICROPHONE",
        description:
            "2 MEMS digital voice microphones 4 dedicated electret microphones for ANC (2 electrets per earpiece)"
    },
    {
        name: "BATTERY",
        description: `
            Rechargeable lithium-ion battery
            Capacity: 1110 mAh 
            Playtime up to 30 hours with Bluetooth and ANC 
            Up to 45 hours with Bluetooth only 
            Up to 42 hours with ANC
            Charging time: about 3.5 hours
        `
    },
    {
        name: "PROXIMITY SENSOR",
        description: "Headphones automatically pause when you take them off"
    },
    {
        name: "TRANSPARENCY MODE",
        description:
            "Transparency mode is available at the touch of a button on the bowl."
    },
    {
        name: "SET",
        description: `
            1.2m audio cable with 3.5mm jack 
            1.25 m USB-A to USB-C cable 
            Air adapter
            Carry case 
            Quick User Guide
        `
    }
];

// общая анимация появления
export const animationShow = {
    initial: (inverse) => ({
        opacity: 0,
        x: inverse ? -100 : 100
    }),
    animate: (delay) => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: 1,
            delay
        }
    }),
    exit: {
        opacity: 0,
        transition: {
            duration: 1
        }
    }
};
