import React from "react";
import {observer} from "mobx-react";
import FeatureContent, {FeatureHeader, FeatureText} from "../FeatureContent";
import GlobalState from "../../../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../../../helpers/constants";
import {motion} from "framer-motion";
import { animationShow } from "helpers/constants";
import "./MicrophoneContent.less";

/**
 * Контент на виде {@link POINT_SETTINGS.Microphone}
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MicrophoneContent = () => {
    return (
        <FeatureContent visible={GlobalState.currentPointIsEqual(POINT_SETTINGS.Microphone)}
                        className="microphone-content">
            <FeatureHeader>
            {['M','I','C','R','O','P','H','O','N','E','—'].map((letter, index, array) =>(
                <motion.span key={letter+index} initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(index*1.4/array.length)}>{letter}</motion.span>))}
            </FeatureHeader>
            <FeatureText>
                <motion.p initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(1.3)}>
                    An array of beamforming microphones isolate and amplify users voice while cancelling out background
                    noise – creating like a virtual room that eliminates the need for a gaming. Instantly adjust
                    ANC/own-voice balance, mute, and more.
                </motion.p>
            </FeatureText>
        </FeatureContent>
    );
}

export default observer(MicrophoneContent);
