import React, {useMemo} from "react";
import {Object3D, SpotLight} from "three";
import {useThree} from "@react-three/fiber";

const DEFAULT_LIGHT_COLOR = 0xffffff;
const DEFAULT_SPOTLIGHT_PROPS = {
    intensity: 1,
    color: DEFAULT_LIGHT_COLOR,
    distance: DEFAULT_LIGHT_COLOR,
    penumbra: 1,
    decay: 1
};

const Lights = () => {

    return (
        <>
            <spotLight {...DEFAULT_SPOTLIGHT_PROPS} position={[8, -7, 0]}/>
            <spotLight {...DEFAULT_SPOTLIGHT_PROPS} position={[-8, -7, 0]} angle={0.4} distance={40}/>
            <spotLight {...DEFAULT_SPOTLIGHT_PROPS} position={[0, 13, 0]} angle={0.6} distance={20}/>

            <WallLights/>

            <CustomSpotLight/>

            <ambientLight intensity={0.3} color={DEFAULT_LIGHT_COLOR}/>
            <directionalLight intensity={1} position={[0, 1, 0]} color={DEFAULT_LIGHT_COLOR}/>
        </>
    );
}

export default Lights;

const CustomSpotLight = () => {
    const light = useMemo(() => new SpotLight(
        DEFAULT_LIGHT_COLOR, 1, 50, Math.PI / 5, 1, 1
    ), []);

    return (
        <>
            <primitive object={light} position={[0, 3, -4]}/>
            <primitive object={light.target} position={[0, -3, 0]}/>
        </>
    );
}

const WallLights = () => {

    const target1 = new Object3D();
    target1.position.set(-11.3, 13.1, -25.32);

    const target2 = new Object3D();
    target2.position.set(7.87, 12.3, -25.32);

    const target3 = new Object3D();
    target3.position.set(14.51, 4.92, -0.24);

    const {scene} = useThree();
    scene.add(target1);
    scene.add(target2);
    scene.add(target3);

    return (
        <>
            <spotLight {...DEFAULT_SPOTLIGHT_PROPS}
                       intensity={1.8}
                       position={[-11.3, -1.71, -5.4]}
                       target={target1}
                       penumbra={0.4}
                       angle={0.48}/>
            <spotLight {...DEFAULT_SPOTLIGHT_PROPS}
                       intensity={1.8}
                       position={[4.19, -2.45, -7.62]}
                       target={target2}
                       penumbra={0.4}
                       angle={0.48}/>
            <spotLight {...DEFAULT_SPOTLIGHT_PROPS}
                       intensity={1.8}
                       position={[4.19, -4.76, -3.19]}
                       target={target3}
                       penumbra={0.4}
                       angle={0.48}/>
        </>
    )
}