import {useEffect, useState} from "react";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export const useOrbit = (
    camera = new THREE.OrthographicCamera(),
    gl = document.getElementById("root"),
    options = {}
) => {
    const [orbit, setOrbit] = useState(null);

    useEffect(() => {
        if (camera) {
            const orbit = new OrbitControls(
                camera,
                document.getElementById("root")
                // gl.domElement
            );
            Object.entries(options).forEach(([key, value]) => {
                orbit[key] = value;
            });

            setOrbit(orbit);

            return () => {
                orbit.dispose();
                setOrbit(null);
            };
        }
    }, [camera]);

    return orbit;
};
