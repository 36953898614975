import React, {useEffect, useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import {AnimationMixer, MeshPhongMaterial} from 'three';
import {observer} from "mobx-react";
import {useFrame} from "@react-three/fiber";
import GlobalState from "../store/AppGlobalState";
import {POINT_SETTINGS} from "../helpers/constants";
import {interpValue} from "../helpers/interp-values";
import AppDataLoadingState from "../store/DataLoadingState";
import NoiseCancelAnimation from "./feature-animations/NoiseCancelAnimation";

/**
 * Признка того, что для возвращения вращения наушников в исходное состояние нужно дополнительный поворот на PI.
 */
let NEED_ADDITIONAL_RETURNING = true;

/**
 * Начальное положение наушников по оси Y
 * @type {number}
 */
const MODEL_INITIAL_Y_POSITION = -1.3;

/**
 * Продолжительность анимации.
 * @type {number}
 */
const ANIMATION_DURATION = 3;

let animationMixer;

const Headphones = () => {

    let MIN_ROTATION_RADIANS = Math.PI;

    const headphonesRef = useRef();
    const headphonesGroupRef = useRef();

    const {scene, animations} = useGLTF('/assets/models/headphones.glb');
    const wormHelper = scene.getObjectByName("metal002");

    // Благодаря <Suspense/> загрузка выполняется в синхронном режиме, что позваляет установить флаг после загрузки
    AppDataLoadingState.headphonesModelIsLoaded = true;

    useEffect(() => {
        if (animations.length) {
            animationMixer = new AnimationMixer(scene);
            animations.forEach(clip => animationMixer.clipAction(clip).play());
            wormHelper.material = new MeshPhongMaterial({color: "#5016F6", transparent: true, opacity: 0});
        }
    }, []);

    useEffect(() => {
        if (!headphonesGroupRef.current || !headphonesRef.current) {
            return;
        }

        if (!GlobalState.currentPointIsEqual(POINT_SETTINGS.SoftAndComfortable) &&
            Math.abs(headphonesGroupRef.current.rotation.y) !== 0) {
            rollbackModelPosition(headphonesGroupRef);
        }

        if (!GlobalState.currentPointIsEqual(POINT_SETTINGS.ErgonomicHeadband)) {
            if (animationMixer.time !== 0) {
                rollbackAnimation();
            }
            toggleOpacity(wormHelper, false);
        } else {
            toggleOpacity(wormHelper, true);
        }
    }, [GlobalState.currentPointOfInterest]);

    useFrame(({clock}, delta) => {
        if (GlobalState.currentPointIsEqual(POINT_SETTINGS.ErgonomicHeadband)) {
            animationMixer.update(delta);
        }

        // Выполняет левитацию и вращение модели
        if (GlobalState.currentPointIsEqual(POINT_SETTINGS.SoftAndComfortable)) {
            headphonesGroupRef.current.rotateY(0.5 * delta);
            const currentCheckRotation = headphonesGroupRef.current.rotation.y + Math.PI / 2;

            // Старт вращения начинается примерно от PI до 0, на основе сравнения предыдущего значения узнаём -
            // увеличивается ли значение или уменьшается. В зависимости от этой фазы при возвращении поворота наушников
            // в исходное положение в радианах потребуется (либо нет) дополнительный поворот на PI
            NEED_ADDITIONAL_RETURNING = currentCheckRotation > MIN_ROTATION_RADIANS;
            MIN_ROTATION_RADIANS = currentCheckRotation;

            headphonesGroupRef.current.translateY(Math.sin(clock.getElapsedTime()) * 0.002);
        }
    })

    return (
        <group ref={headphonesGroupRef} position={[0, MODEL_INITIAL_Y_POSITION, 0]} rotation={[0, Math.PI, 0]}>
            <primitive ref={headphonesRef} object={scene} scale={1.8}/>
            <NoiseCancelAnimation/>
        </group>
    )
}

export default observer(Headphones);

/**
 * Откатывает в исходное состояние положение и вращение модели.
 *
 * @param headphonesGroupRef ссылка на реф модели наушников.
 */
const rollbackModelPosition = (headphonesGroupRef) => {
    interpValue(
        headphonesGroupRef.current.rotation.y,
        // workaround - из-за вращения в радианах не получается определить положение (PI or -PI)
        NEED_ADDITIONAL_RETURNING ? Math.PI : 0,
        value => headphonesGroupRef.current.rotation.y = value,
        undefined,
        1
    );

    interpValue(
        headphonesGroupRef.current.position.y,
        MODEL_INITIAL_Y_POSITION,
        value => headphonesGroupRef.current.position.y = value,
        undefined,
        1
    );
}

/**
 * Откатывает в исходное состояние тайминг анимации. Убирает лишние проходы анимации, чтобы не инвертировать их
 * полностью при откате.
 */
const rollbackAnimation = () => {
    const mixerTime = animationMixer.time;
    const longDuration = mixerTime - (Math.trunc(mixerTime / ANIMATION_DURATION) * ANIMATION_DURATION);

    // находим ближайший тайминг для отката анимации
    const shortestDuration = longDuration > (ANIMATION_DURATION / 2) ? ANIMATION_DURATION : 0;

    interpValue(
        longDuration,
        shortestDuration,
        value => animationMixer.setTime(value),
        () => animationMixer.setTime(0),
        1
    );
}

/**
 * Изменяет прозрачность переданного элемента.
 */
const toggleOpacity = (element, turnOn) => {
    const currentMaterialOpacity = element.material.opacity;

    if ((turnOn && currentMaterialOpacity === 1) || (!turnOn && currentMaterialOpacity === 0)) {
        return;
    }

    interpValue(
        turnOn ? 0 : 1,
        turnOn ? 1 : 0,
        value => element.material.opacity = value,
        undefined,
        0.5
    );
}
