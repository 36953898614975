import React from "react";
import {observer} from "mobx-react";
import {motion} from "framer-motion";
import FeatureContent, {FeatureHeader, FeatureText} from "../FeatureContent";
import GlobalState from "../../../../store/AppGlobalState";
import {animationShow, POINT_SETTINGS} from "helpers/constants";
import "./PrernderViewContent.less";

/**
 * Контент на пререндере.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PrernderViewContent = () => {

    return (
        <FeatureContent visible={GlobalState.currentPointIsEqual(POINT_SETTINGS.Intro)} className="prernder-view-content">
            <FeatureHeader>
                {
                    ['—', 'T', 'H', 'E', ' ', 'J', 'O', 'U', 'R', 'N', 'E', 'Y'].map((letter, index, array) => (
                        <motion.span key={letter + index}
                                     initial={animationShow.initial()}
                                     exit={animationShow.exit}
                                     animate={animationShow.animate(index * 1.4 / array.length)}>
                            {letter}
                        </motion.span>
                    ))
                }
            </FeatureHeader>
            <FeatureText>
                <motion.h2 initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(.3)}>—Get to know it better</motion.h2>
                <motion.p initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(.5)}>
                    Get the most out with many updated features.
                </motion.p>
                <motion.p initial={animationShow.initial()} exit={animationShow.exit} animate={animationShow.animate(.8)}>
                    A host of new features include a Transparency mode that allows you to hear ambient sounds. Proximity
                    sensors automatically play or pause your music when you put on or take off your headphones.
                </motion.p>
            </FeatureText>
        </FeatureContent>
    );
}

export default observer(PrernderViewContent)
