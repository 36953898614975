import { action, computed, makeAutoObservable, reaction } from "mobx";
import { POINT_SETTINGS } from "../helpers/constants";
import audioController from "../components/AudioController";
import {
    addOrRemoveClassInBody,
    BODY_LOADING_STATE_CLASS_NAME,
    BODY_OVERFLOW_HIDDEN_STATE_CLASS_NAME
} from "app";

/**
 * Глобальный стейт для определения состояния частей приложения на различных этапах.
 */
class AppGlobalState {
    /**
     * Признак того, что камера в данный момент выполняет переход. Может использоваться для ограничений каких-либо
     * экшенов.
     */
    isInterpolating = false;

    /**
     * Признак показа канваса с пререндером
     */
    prerenderCanvasCanShow = true;

    /**
     * Признак возможности начала показа 3D-сцены с наушниками.
     */
    //TODO: После добавления презентации с помощью облёта камеры - убрать из проекта с соответствующими правками в компонентах
    threeDHeadphonesCanShow = true;

    /**
     * Текущая (выбранная) точка отображения.
     */
    currentPointOfInterest = POINT_SETTINGS.Intro;
    
    /**
     * Признак того, что можно проигрывать фоновую музыку.
     */
    playBackgroundSound = true;

    /**
     * Признак того, что экран загрузки отключён.
     */
    turnOffLoaderScreen = false;

    /**
     * Признак того, что нужно отключить шум.
     */
    suppressNoise = false;

    /**
     * Признак того, что модальное окно how to buy открыто
     */
    isHowToBuyOpened = false;

    /**
     * Положение скроллинга сцены с интро
     */
    introScrollOffset = 0;

    constructor() {
        makeAutoObservable(this);
        this.initObservation();
    }

    @computed
    currentPointIsOneOfFeature = () => {
        return !this.currentPointIsDefault() && !this.currentPointIsEqual(POINT_SETTINGS.Intro);
    };

    @computed
    currentPointIsDefault = () => {
        return pointOfInterestEquals(
            this.currentPointOfInterest,
            POINT_SETTINGS.Default
        );
    };

    @computed
    currentPointIsEqual = (pointOfInterest) => {
        return pointOfInterestEquals(
            this.currentPointOfInterest,
            pointOfInterest
        );
    };

    @action
    setCurrentPointOfInterest = (pointOfInterest) => {
        this.currentPointOfInterest = pointOfInterest;
    };

    @action
    toggleSuppressNoiseAnimation = () => {
        this.suppressNoise = !this.suppressNoise;
    };

    @action
    toggleHowToBuyModal = () => {
        this.isHowToBuyOpened = !this.isHowToBuyOpened;
    };

    /**
     * Регистрация слущателей для выполнения сайд-эфектов при изменении отслеживаемых полей данного стора.
     */
    initObservation = () => {
        // При переходе на вид отличный от POINT_SETTINGS.NoiseCancel отключить шумоподавление
        reaction(
            () => this.currentPointOfInterest,
            () => {
                if (!this.currentPointIsEqual(POINT_SETTINGS.NoiseCancel)) {
                    this.suppressNoise = false;
                    audioController.stopCityNoiseAudio();
                    audioController.stopCityNoiseSuppressedAudio();
                    audioController.stopTryNowNoiseCancellationAudio();
                }
            }
        );

        // При уходе со страницы загрузки, удаляет класс загрузки с body
        reaction(
            () => this.turnOffLoaderScreen,
            () => {
                addOrRemoveClassInBody(BODY_LOADING_STATE_CLASS_NAME, false);
            }
        );

        // При переходе в модальное окно о товаре, убирает возможность скрола body
        reaction(
            () => this.isHowToBuyOpened,
            () => {
                addOrRemoveClassInBody(
                    BODY_OVERFLOW_HIDDEN_STATE_CLASS_NAME,
                    this.isHowToBuyOpened || this.threeDHeadphonesCanShow
                );
            }
        );
    };
}

const GlobalState = new AppGlobalState();
export default GlobalState;

/**
 * (workaround) MobX создаёт прокси над отслеживаемым объектом из-за чего простое сравнение ломается.
 *
 * @param pointOfInterest_a
 * @param pointOfInterest_b
 * @returns {boolean}
 */
export const pointOfInterestEquals = (pointOfInterest_a, pointOfInterest_b) => {
    return (
        JSON.stringify(pointOfInterest_a) === JSON.stringify(pointOfInterest_b)
    );
};
