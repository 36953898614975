import React from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import SiteDataHeader from "./header/SiteDataHeader";
import SiteDataFooter from "./footer/SiteDataFooter";
import MainContentContainer from "./main-content/MainContentContainer";
import GlobalState from "../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../helpers/constants";
import "./SiteDataContainer.less";

/**
 * UI лэйаут, который не привязан к 3D сцене, по сути рендерится поверх канваса со сценой.
 */
const SiteDataContainer = () => {

    const className = classNames("site-data-container", {"disable-events": GlobalState.threeDHeadphonesCanShow && !GlobalState.isHowToBuyOpened});

    return GlobalState.turnOffLoaderScreen && (
        <div className={className}>
            <SiteDataHeader/>
            <MainContentContainer/>
            <SiteDataFooter/>
            {/*{GlobalState.currentPointIsEqual(POINT_SETTINGS.Intro) && <ScrollLogo/>}*/}
        </div>
    );
}

const ScrollLogo = () => (
    <div className="scroll-logo">
        <div className="scroll-logo__border"></div>
        <span className="scroll-logo__text">scroll</span>
    </div>
)

export default observer(SiteDataContainer);
