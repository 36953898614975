// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../styles/fonts/Proxima-Nova-Font.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../styles/fonts/Proxima-Nova-Font-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Proxima-Nova-Font\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n  font-style: normal;\n  font-weight: normal;\n}\n@font-face {\n  font-family: \"Proxima-Nova-Font\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");\n  font-style: normal;\n  font-weight: 800;\n}\n.features-content {\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/fonts.less","webpack://./src/components/site-data-container/main-content/default-content/FeaturesContent.less"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,+DAAA;EACA,kBAAA;EACA,mBAAA;ACCJ;ADEA;EACI,gCAAA;EACA,+DAAA;EACA,kBAAA;EACA,gBAAA;ACAJ;AATA;EACE,mBAAA;AAWF","sourcesContent":["@font-face {\n    font-family: \"Proxima-Nova-Font\";\n    src: url(\"./fonts/Proxima-Nova-Font.otf\") format(\"opentype\");\n    font-style: normal;\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: \"Proxima-Nova-Font\";\n    src: url(\"./fonts/Proxima-Nova-Font-Bold.otf\") format(\"opentype\");\n    font-style: normal;\n    font-weight: 800;\n}\n\n.fontProximaNova() {\n    font-family: \"Proxima-Nova-Font\";\n}\n","@import \"globalsLess\";\n\n.features-content {\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
