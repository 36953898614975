import classNames from "classnames";
import React from "react";
import "./LeftTopLogo.less";

export const LeftTopLogo = ({inverseColor = false, hideDescription = false}) => {
    const classnames = classNames("company-logo", {
        inverse: inverseColor
    })
    return (
        <div className={classnames}>
            <a href="https://third-dimension.studio" target="_blank" className="company-logo__logo">
                <span>Third</span>
                <span>Dimension</span>
            </a>
            {
            !hideDescription && 
                <div className="company-logo__description">
                    <span>essential device details accompany the release</span>
                </div>
            }
        </div>
    )
};
