import {AnimatePresence, motion} from "framer-motion";
import React from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import "./FeatureContent.less";

// TODO: применить анимацию
const loaderContainerVariants = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0
    }
};

/**
 * Контейнер для отображения описание фичи на соответствующем виде.
 *
 * @param visible
 * @param className
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
// TODO: применить анимацию
const FeatureContent = ({visible, className, children}) => {

    const resultClassName = classNames("feature-content", className);

    return (
        <AnimatePresence>
            {visible && (
                /*<motion.div className={resultClassName}
                            initial={loaderContainerVariants.initial}
                            animate={loaderContainerVariants.animate}
                            exit={loaderContainerVariants.exit}>*/
                <motion.div className={resultClassName}>
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default observer(FeatureContent);

/**
 * Заголовок фичи.
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const FeatureHeader = ({children}) => (
    <div className="feature-header">
        <h1>{children}</h1>
    </div>
);

/**
 * Описание фичи.
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const FeatureText = ({children}) => (
    <div className="feature-text">
        {children}
    </div>
);
