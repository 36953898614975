import React from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import GlobalState, {pointOfInterestEquals} from "../../../store/AppGlobalState";
import {POINT_SETTINGS} from "../../../helpers/constants";
import "./PointsOfInterestNavigation.less";
import audioController from "../../AudioController";

const PointsOfInterestNavigation = () => {
    return (
        <div className="points-of-interest-navigation">
            <PointsOfInterestNavigationButton pointOfInterest={POINT_SETTINGS.ErgonomicHeadband}>
                ergonomic headband
            </PointsOfInterestNavigationButton>
            <PointsOfInterestNavigationButton pointOfInterest={POINT_SETTINGS.SoftAndComfortable}>
                light and comfortable
            </PointsOfInterestNavigationButton>
            <PointsOfInterestNavigationButton pointOfInterest={POINT_SETTINGS.NoiseCancel}>
                noise cancellation
            </PointsOfInterestNavigationButton>
            <PointsOfInterestNavigationButton pointOfInterest={POINT_SETTINGS.Microphone}>
                microphone
            </PointsOfInterestNavigationButton>
        </div>
    );
}

export default observer(PointsOfInterestNavigation);

const PointsOfInterestNavigationButton = observer(({pointOfInterest, children}) => {

    const onClick = () => {
        if (pointOfInterestEquals(pointOfInterest, POINT_SETTINGS.NoiseCancel)) {
            audioController.playNoiseCancellationClickAudio();
        } else {
            audioController.playZoomIn();
        }

        GlobalState.setCurrentPointOfInterest(pointOfInterest);
    };

    const className = classNames(
        "point-of-interest-navigation-button",
        {"active": GlobalState.currentPointIsEqual(pointOfInterest)}
    );

    return (
        <>
            <button className={className} onClick={onClick}>{children}</button>
        </>
    );
});
