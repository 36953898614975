import React, { Suspense, useEffect } from "react";
import { configure } from "mobx"
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, Stats } from "@react-three/drei";
import CameraControls from "./components/camera-controls";
import Lights from "./components/lights";
import Headphones from "./components/headphones";
import { POINT_SETTINGS } from "./helpers/constants";
import Inscriptions from "./components/inscriptions/Inscriptions";
import SiteDataContainer from "./components/site-data-container/SiteDataContainer";
import GlobalState from "./store/AppGlobalState";
import { observer } from "mobx-react";
import RingsGround from "components/rings-ground";
import ReflectorGround from "components/reflector-ground";
import Loader from "./components/loader/Loader";
import BackgroundAudio from "./components/BackgroundAudio";
import MicrophoneAnimation from "./components/feature-animations/MicrophoneAnimation";
import AppDataLoadingState from "./store/DataLoadingState";
import { Walls } from "./components/Walls";
import CommonAudio from "./components/CommonAudio";
import { RotateDisplay } from "./components/rotate-display/RotateDisplay";
import ScrollIntro from "components/scroll-intro";
import "./styles/common.less";

/**
 * Класс для установки на тег body. Используется для предотвращения скроллинга и, как следствие, прокрутку перерендера
 * за экраном загрузки во время загрузки экрана.
 *
 * @type {string}
 */
export const BODY_LOADING_STATE_CLASS_NAME = "loading-state";
export const BODY_OVERFLOW_HIDDEN_STATE_CLASS_NAME = "overflow-hidden";

/**
 * Добавляет/удаляет класс в теге body.
 *
 * @param className
 * @param add признак добавления/удаления (true - добавить, false - удалить). По-дефолту true
 */
// TODO: убрать куда-то в какой-нибудь хелпер
export const addOrRemoveClassInBody = (className, add = true) => {
    if (add) {
        document.body.classList.add(className);
    } else {
        document.body.classList.remove(className);
    }
}

addOrRemoveClassInBody(BODY_LOADING_STATE_CLASS_NAME, true)

configure({
    enforceActions: "never",
});

const App = () => {
    useEffect(() => {
        GlobalState.setCurrentPointOfInterest(POINT_SETTINGS.Default);
    }, []);
    addOrRemoveClassInBody(BODY_OVERFLOW_HIDDEN_STATE_CLASS_NAME, GlobalState.threeDHeadphonesCanShow)


    return (
        <div className="App">
            <Suspense fallback={null}>
                <Canvas
                    className="three-d-canvas"
                    mode="concurrent"
                    shadows
                    gl={{ antialias: true }}
                    style={{ position: "fixed" }}>
                    <fog attach="fog" args={['#17171b', 2, 31]} />
                    <PerspectiveCamera
                        makeDefault
                        far={100}
                        near={0.1}
                        fov={55}
                        zoom={POINT_SETTINGS.Intro.zoom}
                        position={POINT_SETTINGS.Intro.cameraPosition}/>

                    <ScrollIntro />

                    <CameraControls />
                    <Lights />
                    <Walls />

                    <Headphones />

                    <ReflectorGround />
                    <RingsGround />
                    <MicrophoneAnimation />
                    <Inscriptions />
                    <BackgroundAudio />
                    <CommonAudio />
                    {/*<Stats/>*/}
                </Canvas>
                {AppDataLoadingState.allMediaIsLoaded && <SiteDataContainer />}
            </Suspense>

            <Loader />
            <RotateDisplay />
        </div>
    );
}

export default observer(App);
