import { observer } from 'mobx-react';
import React from 'react';
import GlobalState from 'store/AppGlobalState';
import SiteDataFooter from '../footer/SiteDataFooter';
import { LeftTopLogo } from '../header/LeftTopLogo';
import {AnimatePresence, motion} from "framer-motion";
import "./HowToBuyModal.less"
import { MainCharacteristics } from './MainCharacteristics';

/**
 * Компонент модального окна с информацией о продукте
 */
export const HowToBuyModal = observer(({isOpened = false}) => {
    const { toggleHowToBuyModal } = GlobalState;

    const animationVariants = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1,
            transition: {duration: 1}
        },
        exit: {
            opacity: 0,
            transition: {duration: 1}
        }
    };

    return (
        <AnimatePresence>
            {isOpened &&
                <motion.section
                    className="how-to-buy"
                    initial={animationVariants.initial}
                    animate={animationVariants.animate}
                    exit={animationVariants.exit}
                >
                    <header className="how-to-buy__header">
                        <LeftTopLogo inverseColor hideDescription/>
                        <div className="how-to-buy__close" onClick={toggleHowToBuyModal}/>
                    </header>
                    <MainCharacteristics/>
                    <SiteDataFooter inverseColor/>
                </motion.section>
            }
        </AnimatePresence>
    )
})
