import { computed, makeAutoObservable } from "mobx";

/**
 * Класс содержит информацию о статусах загрузки ресурсов.
 */
class DataLoadingState {
    /**
     * Признак процесса загрузки модели.
     */
    headphonesModelIsLoaded = false;

    /**
     * Признак процесса загрузки фоновой музыки.
     */
    backgroundSoundIsLoaded = false;

    allUISoundsIsLoaded = false;

    constructor() {
        makeAutoObservable(this);
    }

    @computed
    get allMediaIsLoaded() {
        return (
            this.backgroundSoundIsLoaded &&
            this.headphonesModelIsLoaded &&
            this.allUISoundsIsLoaded
        );
    }
}

const AppDataLoadingState = new DataLoadingState();
export default AppDataLoadingState;
