import React, {useRef, useMemo, useEffect, useState} from 'react'
import {useFrame} from "@react-three/fiber";
import {Object3D} from "three";
import {observer} from "mobx-react";
import {POINT_SETTINGS} from 'helpers/constants';
import GlobalState from 'store/AppGlobalState';
import {interpValue} from 'helpers/interp-values';

const color = "#5016F6";
const RING_COUNT = 12;
const INNER_RADIUS = 0.25
const THICKNESS_RADIUS = 2
const SLOTS_PER_RING = 100;
const FADE_WIDTH = 0.07;
const SPEED = 2.5;
const PARTICLE_SIZE = 0.03;

let opacityOfViewCoefficient = 0; // коеф-нт прозрачности, для плавного показа и удаления кругов при смене вида

/**
 * Компонент анимации шумодава. Отображается на виде Noise cancellation в виде разлетающихся частиц. Привязан к
 * контексту ThreeJS на канвасе.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NoiseCancelAnimation = () => {
    const mesh = useRef();
    const dummy = useMemo(() => new Object3D(), []);

    const [rotation, setRotation] = useState({x: -0.83, y: 1.83, z: -0.02});
    const [position, setPosition] = useState({x: 1.99, y: -0.135, z: -0.715});

    let count = RING_COUNT * SLOTS_PER_RING;

    const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < RING_COUNT; i++) {
            for (let j = 0; j < SLOTS_PER_RING; j++) {
                if (Math.random() > 0.5) continue;

                const t = i / RING_COUNT;
                const angle = j * Math.PI * 2 / SLOTS_PER_RING;
                temp.push({t, angle});
            }
        }
        return temp;
    }, []);


    useFrame((st, dt) => {
        particles.forEach((pt, i) => {
            // pt.t -= -1+SPEED * 0.1 * dt; //reverse
            pt.t += SPEED * 0.1 * dt;
            pt.t %= 1;
            const t = pt.t;
            const y = (INNER_RADIUS + t * THICKNESS_RADIUS) * Math.sin(pt.angle);
            const x = (INNER_RADIUS + t * THICKNESS_RADIUS) * Math.cos(pt.angle);
            dummy.position.set(x, y, 0);
            //fade from 0, hold 1, fade to 0

            let scale;

            if (t < FADE_WIDTH) {
                scale = t / FADE_WIDTH;
            } else {
                scale = t > (1 - FADE_WIDTH) ? ((1 - t) / FADE_WIDTH) : 1;
            }

            scale *= opacityOfViewCoefficient;

            dummy.scale.set(scale, scale, scale);
            dummy.rotation.set(0, 0, pt.angle);
            dummy.updateMatrix();
            mesh.current.setMatrixAt(i, dummy.matrix);
        });

        mesh.current.instanceMatrix.needsUpdate = true;
    });

    // Выполняет плавное изменение коэфициента прозрачности.
    useEffect(() => {
        const showParticles = GlobalState.currentPointIsEqual(POINT_SETTINGS.NoiseCancel) && GlobalState.suppressNoise;
        const [from, to] = showParticles ? [0, 1] : [1, 0];
        if (from === opacityOfViewCoefficient) {
            interpValue(
                from,
                to,
                value => opacityOfViewCoefficient = value,
                undefined,
                showParticles ? 0.5 : 0.3
            );
        }
    }, [GlobalState.currentPointOfInterest, GlobalState.suppressNoise]);

    const group = useRef();

    return (
        <group ref={group}>
            <instancedMesh ref={mesh} args={[null, null, count]} scale={0.17}
                           position={[position.x, position.y, position.z]}
                           rotation={[rotation.x, rotation.y, rotation.z]}>
                <planeBufferGeometry attach="geometry" args={[PARTICLE_SIZE, PARTICLE_SIZE]}/>
                <meshBasicMaterial attach="material" color={color}/>
            </instancedMesh>
        </group>
    )
}

export default observer(NoiseCancelAnimation);
