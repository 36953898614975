import React from "react";
import {observer} from "mobx-react";
import FeatureContent, {FeatureHeader} from "../FeatureContent";
import GlobalState from "../../../../store/AppGlobalState";
import {motion} from "framer-motion";
import "./FeaturesContent.less";
import {animationShow} from "helpers/constants";

/**
 * Контент на стандартном виде {@link POINT_SETTINGS.Default}.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const FeaturesContent = () => {
    return (
        <FeatureContent visible={GlobalState.threeDHeadphonesCanShow && GlobalState.currentPointIsDefault()}
                        className="features-content">
            <FeatureHeader>{['—', 'F', 'E', 'A', 'T', 'U', 'R', 'E', 'S'].map((letter, index, array) => (
                <motion.span key={letter + index}
                             initial={animationShow.initial()}
                             exit={animationShow.exit}
                             animate={animationShow.animate(index * 1.4 / array.length)}>
                    {letter}
                </motion.span>
            ))}</FeatureHeader>
        </FeatureContent>
    );
}

export default observer(FeaturesContent);
