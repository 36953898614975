import { animationShow, CHARACTERISTICS } from 'helpers/constants';
import React from 'react';
import {motion} from "framer-motion";
import "./MainCharacteristics.less"

const animationItems = {
    initial: {
        opacity: 0,
        x: 100
    },
    animate: (index) => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: 1.5,
            delay: 0.2*index
        }
    })
};

/**
 * Компонент с основными характеристиками продукта
 */
export const MainCharacteristics = () => {
    return (
            <main className="characteristics">
                <div className="characteristics__column">
                    <h1
                        initial={animationShow.initial()}
                        animate={animationShow.animate(0)}
                        className="characteristics__title"
                    >   
                    {['H', 'o', 'w', ' ', 't', 'o', ' ', 'b', 'u', 'y'].map(
                        (letter, index) => (
                            <motion.span
                                initial={animationShow.initial()}
                                animate={animationShow.animate(index * 0.1)}
                                className="pre"
                                key={letter+index}
                            >{letter}</motion.span>
                        ) 
                    )}
                    </h1>
                    <ul className="characteristics__list">
                        {CHARACTERISTICS.map((characteristic, index) => (
                            <li
                                className="characteristics__item"
                                key={index}
                            >
                                <span className="characteristics__name">{characteristic.name}</span>
                                <span className="characteristics__description">{characteristic.description}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="characteristics__column">
                    <motion.h2 initial={{opacity: 0}} animate={{opacity: 1, transition: {delay:1.5, duration: .5}}} className="characteristics__subtitle">
                        All assets and Copies are used just for information and to show possibilities of 3d visualisation. 
                        Third-dimenion does not provide any devices and does not make sales of it. 
                        For any information please contact <br/> <a href="mailto:v@third-dimension.studio">v@third-dimension.studio</a>
                    </motion.h2>

                    <motion.a initial={{opacity: 0}} animate={{opacity: 1, transition: {delay:1.5, duration: .5}}} className="characteristics__store-button" href="https://calendly.com/v-3d-dimension/30min">
                        Book a call
                    </motion.a>
                </div>
            </main>
    )
}
