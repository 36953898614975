import React from 'react';
import {useProgress} from '@react-three/drei';
import {motion, AnimatePresence} from "framer-motion";
import GlobalState from "../../store/AppGlobalState";
import {observer} from "mobx-react";
import AppDataLoadingState from "../../store/DataLoadingState";
import "./Loader.less";

const loaderContainerVariants = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {
        opacity: 0,
        transition: {duration: 0.5}
    }
};

const continueVariants = {
    initial: {opacity: 0},
    animate: {
        opacity: 1,
        transition: {duration: 1}
    },
    exit: {
        opacity: 0,
        transition: {duration: 0.5}
    }
};

/**
 * Компонент для отображения загрузки контента. Так же содержит логику показа кнопки "продолжить".
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Loader = () => {
    const {progress} = useProgress();

    const onButtonClick = () => {
        GlobalState.turnOffLoaderScreen = true;
    }

    return (
        <AnimatePresence>
            {(!AppDataLoadingState.allMediaIsLoaded || !GlobalState.turnOffLoaderScreen) && (
                <motion.div className="loader"
                            initial={loaderContainerVariants.initial}
                            animate={loaderContainerVariants.animate}
                            exit={loaderContainerVariants.exit}
                            onClick={AppDataLoadingState.allMediaIsLoaded ? onButtonClick : undefined}
                >

                    <div className="progress-circle">
                        <span>{Math.floor(progress)}%</span>
                        {AppDataLoadingState.allMediaIsLoaded && 
                            <motion.p {...continueVariants} className="loader__continue">Click to continue</motion.p>}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default observer(Loader);
