import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app"

const root = ReactDOM.createRoot(document.getElementById("root"));

if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
}

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
